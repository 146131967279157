export class PasswordPolicy {
    min_length: number;
    min_uppercase: any;
    min_lowercase: any;
    min_numbers: number;
    special_character: any;
    passwd_exp_limit: number;
    passwd_email_notify: number;
    failed_attempts : number;
    passwd_history: number;
    sessiontime: number;

};

