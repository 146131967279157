<div class="modal-header">
    <h4 class="modal-title">Alert</h4>
  </div>
  <div class="modal-body">
    <h2>Session Expire</h2>
    <p>The session has been expired. Do you want to continue?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="logout()">Logout</button>
    <button type="button" class="btn btn-success" (click)="stayLoggedIn()">Stay Logged In</button>
  </div>